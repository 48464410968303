import React,{useState} from 'react'
import { clients }  from '../data/clients'

const ClientProfile = () => {
   const [client, setClient] = useState(clients[0])

   const handleNext = () => {
    const currentIndex = clients.indexOf(client);
    const nextIndex = (currentIndex + 1) % clients.length;
    setClient(clients[nextIndex]);
   }

   const handlePrevious = () => {
    const currentIndex = clients.indexOf(client);
    const previousIndex = (currentIndex - 1 + clients.length) % clients.length;
    setClient(clients[previousIndex]);
   }
  return (
        <>
           <div className="flex justify-between items-center px-4 md:px-0">
                <div class="inline-block my-6 rounded font-primary text-3xl text-black mb-5">Client profile</div>
                {clients.length > 1 && (<div className="profile-nav flex items-center justify-end gap-3">
                        <button className="px-5 py-2 bg-blue-950/10 hover:bg-blue-950/20 text-blue-950 font-semibold" onClick={handlePrevious}>Previous</button>
                        <button className="px-5 py-2 bg-blue-950/10 hover:bg-blue-950/20 text-blue-950 font-semibold" onClick={handleNext}>Next</button>
                    </div>)}
           </div>
            <div className="bg-blue-950 mx-auto lg:flex gap-x-2 items-center">
                <div className="client-image">
                    <img src={client.image} alt='' className="h-100 md:h-full w-auto object-cover" style={{ mixBlendMode:'none'}} />
                </div>
                <div className="client-description">                    
                    <div className="px-4 md:px-16 py-6">
                        <h4 className="text-4xl font-normal font-primary text-white">{client.name}</h4>
                        <div className="text-sm text-justify text-white/50 leading-5 my-6">
                            {client.summary}
                        </div>
                        <h4 className="font-semibold mb-5 text-white hidden lg:block">Sites We Manage</h4>
                        <div className="hidden lg:flex items-center gap-x-6">
                        {client.sites.length > 0 &&(client.sites.map((site, index)=>(
                            <span key={index} className="block py-2 px-4 text-white text-sm font-secondary font-semibold bg-white/10">{site.name}</span>
                        )))}
                    </div>
                </div>
                </div>
                
            </div>
        </>
       
  )
}

export default ClientProfile